.square {
    cursor: pointer;
    background-color: #00FF00;
    width: 80px;
    height: 80px;
    margin: .5px;
    border-radius: 5px;
    font-size: 48px;
    display: inline-block;
    text-align: center;
    line-height: 80px;
    border: none;
    color: black;
}

.change-background,
.square:hover {
    background-color: #228B22;
}

.red-font {
    color: red;
}