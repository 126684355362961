.StartGame {
    text-align: center;
}

.Choose-Option {
    display: flex;
    justify-content: center;
    gap: 50px;
}

.button {
    border: 1px solid black;
    background-color: #00FF00;
    cursor: pointer;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simple-button {
    border: 1px solid black;
    cursor: pointer;
    width: 100px;
    margin: 0 auto;
    background-color: #00FF00;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:hover {
    background-color: #228B22;
    color: white
}